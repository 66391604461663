<template>
  <div>
    <v-container class="px-0 py-0 bg-color-theme fill-height">
      <v-app-bar app dense color="primary" class="mb-auto">
        <template>
          <router-link to="/">
            <v-img
              width="175"
              :src="require('@/assets/images/cp-app-logo.svg')"
            ></v-img>
          </router-link>
          <v-spacer></v-spacer>
        </template>
      </v-app-bar>
    </v-container>
    <v-container class="font-Noto-Sans px-8">
      <div class="text-center text-primary title-text fw-700">
        <div class="font-16px">
          管理店舗に
        </div>
        <div class="font-18px">
          {{
            this.shopDetail[0].shop.store_display_name
              ? this.shopDetail[0].shop.store_display_name
              : this.shopDetail[0].shop.store_name
          }}
        </div>
        <div class="font-16px">
          が追加されました。
        </div>
      </div>
      <v-btn
        :loading="loading"
        @click="updateConfirmation"
        color="primary"
        block
        >確認</v-btn
      >
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ConfirmInvitaion",
  data() {
    return {
      shopDetail: {},
      loading: false
    };
  },
  created() {
    this.shopDetail = this.$store.getters.getShopAuthUser;
  },
  methods: {
    async updateConfirmation() {
      this.loading = true;
      let data = {};
      data.userShopId = this.shopDetail[0].id;
      data["user_id"] = this.shopDetail[0].user_id;
      await this.$store
        .dispatch("USER_SHOP_UPDATE_STATUS", data)
        .then(response => {
          if (response.status === 200) {
            let authUserData = {
              shop_id: this.shopDetail[0].shop_id, //Shop Id
              user_id: this.shopDetail[0].user_id
            };

            this.$store
              .dispatch("SHOP_USER_AUTH", authUserData)
              .then(response => {
                this.$router.push({ name: "managedStores" });
                this.$store.commit("hideAlert", {
                  text: "招待が確認されました",
                  successStatus: "info"
                });
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          if (error?.data?.errors)
            this.$refs.observer.setErrors(error?.data?.errors);
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.title-text {
  margin-top: 60px;
  margin-bottom: 68px;
}
</style>
